export const mapGenderInt =(int) => {
    let values = {
        10:'Male',
        20:'Female'
    };
    return values[int];
}

export const mapGenderStr = (str) => {
    let values = {
        'Male': 10,
        'Female': 20
    };
    return values[str];
}