export const BASE_URL = process.env.NEXT_STATIC_ENV === 'local' ? '/' : '/auto-quote/';

export const IMAGE_PATH = BASE_URL + 'images/';
export const SHARED_ASSETS_PATH = process.env.SHARED_ASSETS_PATH;
export const SVG_PATH = BASE_URL + 'svg/';
export const FAVICON_PATH = BASE_URL + 'favicons/';
export const CARRIER_IMAGE_PATH = SHARED_ASSETS_PATH + "img/carrier-logos/";
export const PARTNER_IMAGE_PATH = SHARED_ASSETS_PATH + "img/partner-logos/";
export const FONTS_PATH = SHARED_ASSETS_PATH + "fonts/";

export const DEFAULT_WARM_TRANSFER_MESSAGE = "Due to some details provided you will need to discuss your coverage requirements with an agent. Don’t worry though, this will only take a few minutes.";
export const DEFAULT_CONSOLE_LOG_MESSAGE = "SW4gdGhlIHdvcmxkIEkgc2VlIHlvdSBhcmUgc3RhbGtpbmcgZWxrIHRocm91Z2ggdGhlIGRhbXAgY2FueW9uIGZvcmVzdHMgYXJvdW5kIHRoZSBydWlucyBvZiBSb2NrZWZlbGxlciBDZW50ZXIuIFlvdSdsbCB3ZWFyIGxlYXRoZXIgY2xvdGhlcyB0aGF0IHdpbGwgbGFzdCB5b3UgdGhlIHJlc3Qgb2YgeW91ciBsaWZlLiBZb3UnbGwgY2xpbWIgdGhlIHdyaXN0LXRoaWNrIGt1ZHp1IHZpbmVzIHRoYXQgd3JhcCB0aGUgU2VhcnMgVG93ZXJzLiBBbmQgd2hlbiB5b3UgbG9vayBkb3duLCB5b3UnbGwgc2VlIHRpbnkgZmlndXJlcyBwb3VuZGluZyBjb3JuLCBsYXlpbmcgc3RyaXBlcyBvZiB2ZW5pc29uIG9uIHRoZSBlbXB0eSBjYXIgcG9vbCBsYW5lIG9mIHNvbWUgYWJhbmRvbmVkIHN1cGVyaGlnaHdheXMu";

export const LIVE_CHAT_CARRIERS = ["pennymac"];

export const COOKIE_AFFILIATE_CODE = "affiliate-code";
export const COOKIE_AFFILIATE_APPLICATION_ID = "affiliate-application-id";
export const GOOGLE_CLICK_ID = "gclid";

export const DISPLAY_DATE_FORMAT = "MMMM do, yyyy";

export const CARRIER_NATIONWIDE_NO_PAYMENT_PROVIDER_ID = 20;

export const BUSINESS_LIST = {
    "PersonalAuto": "Auto",
    "PersonalHome": "Homeowner"
};

export const PAY_MONTHLY_LABEL_VALUE = 'Pay monthly';
export const PAY_YEARLY_LABEL_VALUE = 'Pay in full';
export const PAY_EFT_LABEL_VALUE = 'Pay by EFT';

export const PAY_MONTHLY_LABEL = 'Monthly';
export const PAY_YEARLY_LABEL = 'In Full';
export const PAY_EFT_LABEL = 'EFT';

export const COMPLETE_PAYMENT_EXTERNAL_SOURCE = 'ExternalSystem';
export const COMPLETE_PAYMENT_CONSUMER_SOURCE = 'Consumer';

export const warmTransferTypes =
{
    GET_VEHICLE: {
        TIMEOUT: "getVehiclesTimeout",
        ERROR: "getVehiclesError"
    },
    GET_HOUSEHOLD_DISCOVERY: {
        TIMEOUT: "getDriversTimeout",
        ERROR: "getDriversError"
    },
    GET_QUOTES: {
        TIMEOUT: "getQuotesTimeout",
        ERROR: "getQuotesError"
    },
    SAVE: {
        TIMEOUT: "saveApplicationTimeout",
        ERROR: "saveApplicationError"
    },
    OTHER: {
        TIMEOUT: "",
        ERROR: ""
    },
    OUTOFSTATE: "outOfState",
    NO_QUOTE:"noQuote",
    UNKNOWN:""
}

export const HOLIDAYS = [
    {
        "from": "November 22, 2023 13:00:00",
        "to": "November 27, 2023 00:00:00",
        "title": "Thanksgiving",
        "message": "Call center closed Wed 1pm EST until Mon 27th",
        "mobile": ["Closed Wed 1pm", "EST until Mon 27th"]
    },
    {
        "from": "December 25, 2023 00:00:00",
        "to": "December 27, 2023 00:00:00",
        "title": "Holiday",
        "message": "Call center closed Mon 25th until Wed 27th",
        "mobile": ["Closed until Wed 27th"]
    },
    {
        "from": "December 29, 2023 12:00:00",
        "to": "January 1, 2024 00:00:00",
        "title": "Holiday",
        "message": "Call center closed Fri 12pm EST until Tues 2nd Jan",
        "mobile": ["Closed until Tues 2nd Jan"]
    }
];

export const BUSINESS_HOURS = {
    "opens": "8:00",
    "closes": "20:30"
}