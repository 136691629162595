/*
 * Trap Modal in Focus.
 *
 * Prevents tabbing outside the modalId given
 * Focus applied to elementId
 */
export const trapFocusInModal = (modalId, elementId, overflow=true) => {

    const modal = document.querySelector('#' + modalId);

    if (modal) {
        const getVisibleContent = (content) => {
            return new Promise((resolve) => {
                const visibleElements = []
                content.forEach(element => {
                    const visible = !!(element.offsetWidth || element.offsetHeight || element.getClientRects().length);
                    if (visible) {
                        visibleElements.push(element)
                    }
                });
                resolve(visibleElements);
            })
        }

        const trap = (e) => {
            let isTabPressed = e.key === 'Tab' || e.keyCode === 9;
            if (!isTabPressed) { return; }
            if (e.shiftKey) {
                if (document.activeElement === firstFocusableElement) {
                    lastFocusableElement.focus();
                    e.preventDefault();
                }
            } else {
                if (document.activeElement === lastFocusableElement) {
                    firstFocusableElement.focus();
                    e.preventDefault();
                }
            }
        }

        const focusableElements = "button, [href], input, select, textarea, [tabindex]:not([tabindex='-1'])";
        const focusableContent = modal.querySelectorAll(focusableElements);
        const visibleContent = getVisibleContent(focusableContent);
        let firstFocusableElement, lastFocusableElement;

        visibleContent.then(elements => {
            firstFocusableElement = elements[0];
            lastFocusableElement = elements[elements.length - 1];

            let defaultFocusElement = firstFocusableElement;

            if (elementId) {
                const defaultField = document.getElementById(elementId);
                if (defaultField) {
                    defaultFocusElement = defaultField
                    if (defaultFocusElement.type === 'radio') {
                        const radioButtons = document.getElementsByName(defaultFocusElement.name);
                        radioButtons.forEach(option => {
                            if (option.checked) {
                                defaultFocusElement = option;
                            }
                        })
                    }
                }
            }

            if (overflow) {
                document.body.style.overflow = "hidden";
            } else {
                document.body.style.overflow = null
            }

            document.removeEventListener('keydown', trap);
            document.addEventListener('keydown', trap);
            if (defaultFocusElement) {
                defaultFocusElement.focus();
            }
        })
    }

}