/*
 * Kebab Time.
 *
 * Return string-in-kebab-case
 */
export const kebabTime = (str) => {
    if (str === undefined) {
        return;
    }
    return str.replace(/[^0-9a-zA-Z\s]/gi, " ").replace(/\s\s+/g, " ").replace(/\s+/g, "-").toLowerCase();
}