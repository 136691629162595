module.exports = {
    "": "Please Select",
    "MED01": "Administrative Assistant",
    "MED02": "Assistant - Medic/Dent/Vet",
    "MED03": "Chiropractor",
    "MED04": "Clergy",
    "MED05": "Clerk",
    "MED06": "Client Care Worker",
    "MED07": "Dental Hygienist",
    "MED08": "Dentist",
    "MED09": "Dietician",
    "MED10": "Doctor",
    "MED11": "Hospice Volunteer",
    "MED12": "Lab Assistant",
    "MED13": "Mortician",
    "MED14": "Nurse - C.N.A.",
    "MED15": "Nurse - LPN",
    "MED16": "Nurse - RN",
    "MED17": "Nurse Practitioner",
    "MED18": "Optometrist",
    "MED19": "Orthodontist",
    "MED21": "Paramedic/E.M. Technician",
    "MED22": "Pharmacist",
    "MED23": "Physical Therapist",
    "MED24": "Psychologist",
    "MED25": "Receptionist/Secretary",
    "MED26": "Social Worker",
    "MED27": "Support Services",
    "MED28": "Technician",
    "MED29": "Therapist",
    "MED30": "Veterinarian",
    "MED20": "Other"
}