module.exports = {
    "": "Please Select",
    "REP01": "Building Maintenance Engineer",
    "REP02": "Custodian/Janitor",
    "REP03": "Electrician",
    "REP04": "Field Service Technician",
    "REP05": "Handyman",
    "REP06": "Heat/Air Conditioner Repairman",
    "REP07": "Housekeeper/Maid",
    "REP08": "Landscape/Grounds Maintenance",
    "REP09": "Maintenance Mechanic",
    "REP10": "Mechanic",
    "REP11": "Other"
}