module.exports = {
    "": "Please Select",
    "ENG01": "Actuary",
    "ENG02": "Administrative Assistant",
    "ENG03": "Analyst",
    "ENG04": "Architect",
    "ENG05": "Chemist",
    "ENG06": "Clerk",
    "ENG07": "Clinical Data Coordinator",
    "ENG08": "Drafter",
    "ENG09": "Engineer",
    "ENG10": "Lab Assistant",
    "ENG11": "Manager - Project",
    "ENG12": "Manager - R&D",
    "ENG13": "Mathematician",
    "ENG15": "Receptionist/Secretary",
    "ENG16": "Research Program Director",
    "ENG17": "Researcher",
    "ENG18": "Scientist",
    "ENG19": "Sociologist",
    "ENG20": "Surveyor/Mapmaker",
    "ENG21": "Technician",
    "ENG14": "Other"
}