const useFormatText = () => {

    const clean = (string) => {
        return string.split(/(?=[A-Z])/).join(" ").replace(/[^a-zA-Z\d]/g, " ").replace(/\s\s+/g, " ");
    };

    const minulize = (string) => {
        return string[0].toLowerCase() + string.slice(1);
    };

    const capitalize = (string) => {
        return string[0].toUpperCase() + string.slice(1).toLowerCase();
    };

    const snakeCase = (string) => {
        return clean(string).replace(/\s+/g, "_").toLowerCase();
    };

    const kebabCase = (string) => {
        return clean(string).replace(/\s+/g, "-").toLowerCase();
    };

    const pascalCase = (string) => {
        return clean(string).split(" ").map((word) => capitalize(word)).join("");
    };

    const camelCase = (string) => {
        return minulize(pascalCase(string));
    };

    return {capitalize, snakeCase, kebabCase, pascalCase, camelCase};

};


export default useFormatText;