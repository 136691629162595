import fetch from "isomorphic-unfetch";
import {getTokenFromCookie} from "./get-token-from-cookie";


export const getPostAPI = async (endpoint, data) => {

    const url = process.env.NEXT_STATIC_RTU_API + "/" + endpoint
    const settings = {
        "async": true,
        "method": "post",
        "headers": {
            'Content-Type': 'application/json',
            'X-AUTO-CSRF-TOKEN': getTokenFromCookie('AUTO-CSRF-TOKEN'),
            'Accept': 'application/json'
        }
    };

    settings.body = JSON.stringify(data);

    let statusCode;

    return await fetch(url, settings)
        .then((res) => {
            statusCode = res.status;
            return res.json();
        })
        .then((result) => {
            result.IsSuccessful = statusCode === 200;
            return result;
        }, (error) => {
            return false;
        });
}