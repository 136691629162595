/*
 * Get Token from Cookie.
 *
 * Extract the CORS token from the cookie
 */
export const getTokenFromCookie = (cname) => {
    let name = cname + "=";
    let decodeCookie = decodeURIComponent(document.cookie);
    if (decodeCookie) {
        let ca = decodeCookie.split(";");
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
    }
    return "nope";
}