import { forwardRef } from "react";
import {Image} from "react-bootstrap";
import {SVG_PATH} from "@constants";


const SVG = forwardRef((props, ref) => {

    const {src, img, ...rest} = props;
    const svg = img ?? SVG_PATH + src.trim() + ".svg";

    return (
        <Image
            ref={ref}
            src={svg}
            alt={""}
            {...rest}
        />
    );

})

export default SVG;
