module.exports = {
    "": "Please Select",
    "ART01": "Actor",
    "ART02": "Administrative Assistant",
    "ART03": "Announcer/Broadcaster",
    "ART04": "Artist/Animator",
    "ART05": "Author/Writer",
    "ART06": "Choreography/Dancer",
    "ART07": "Clerk",
    "ART08": "Composer/Director",
    "ART09": "Curator",
    "ART10": "Designer",
    "ART11": "Editor",
    "ART12": "Journalist/Reporter",
    "ART13": "Musician/Singer",
    "ART15": "Photographer",
    "ART16": "Printer",
    "ART17": "Producer",
    "ART18": "Production Crew",
    "ART19": "Projectionist",
    "ART20": "Receptionist/Secretary",
    "ART21": "Ticket Sales/Usher",
    "ART14": "Other"
}