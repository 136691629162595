module.exports = {
    "": "Please Select",
    "PER01": "Caregiver",
    "PER02": "Dry Cleaner/Laundry",
    "PER03": "Hair Stylist/Barber",
    "PER04": "Housekeeper",
    "PER05": "Manicurist",
    "PER06": "Masseuse",
    "PER07": "Nanny",
    "PER09": "Pet Services",
    "PER10": "Receptionist/Secretary",
    "PER08": "Other"
}