import Agriculture from "@config/occupations/agriculture"
import ArtMediaDesign from "@config/occupations/art-media-design"
import Banking from "@config/occupations/banking"
import Business from "@config/occupations/business"
import Construction from "@config/occupations/construction"
import Education from "@config/occupations/education"
import Engineering from "@config/occupations/engineering"
import FoodServices from "@config/occupations/food-services"
import Government from "@config/occupations/government"
import Home from "@config/occupations/home"
import Infantry from "@config/occupations/infantry"
import Insurance from "@config/occupations/insurance"
import Legal from "@config/occupations/legal"
import Medical from "@config/occupations/medical"
import Personal from "@config/occupations/personal"
import Production from "@config/occupations/production"
import Repair from "@config/occupations/repair"
import Retired from "@config/occupations/retired"
import Sport from "@config/occupations/sport"
import Student from "@config/occupations/student"
import Travel from "@config/occupations/travel"
import Unemployed from "@config/occupations/unemployed"

/*
 * Industry and Occupation Lookup.
 */
export const occupationLookup = (industry) => {

    let occupations = {
        "": {"": "Please Select"},
        "AGR" : Agriculture,
        "ART" : ArtMediaDesign,
        "BNK" : Banking,
        "BUS" : Business,
        "CON" : Construction,
        "EDU" : Education,
        "EAS" : Engineering,
        "FDS" : FoodServices,
        "GOV" : Government,
        "HO"  : Home,
        "INF" : Infantry,
        "INS" : Insurance,
        "LLS" : Legal,
        "MED" : Medical,
        "PER" : Personal,
        "PRD" : Production,
        "REP" : Repair,
        "RET" : Retired,
        "SPT" : Sport,
        "ST"  : Student,
        "TRA" : Travel,
        "UNEM": Unemployed
    };

    return occupations[industry];
}