module.exports =  {
    "": "Please Select",
    "TRA01": "Administrative Assistant",
    "TRA02": "Air Traffic Control",
    "TRA03": "Airport Operations Crew",
    "TRA04": "Bellhop/Porter",
    "TRA05": "Clerk",
    "TRA06": "Crane Loader/Operator",
    "TRA07": "Dispatcher",
    "TRA08": "Driver - Bus/Streetcar",
    "TRA09": "Driver - Taxi/Limo",
    "TRA10": "Driver - Truck/Delivery",
    "TRA11": "Flight Attendant",
    "TRA13": "Laborer",
    "TRA14": "Longshoreman",
    "TRA15": "Manager - Warehouse/District",
    "TRA16": "Mate/Sailor",
    "TRA18": "Parking Lot Attendant",
    "TRA19": "Pilot/Captain/Engineer",
    "TRA20": "Railroad Worker",
    "TRA21": "Receptionist/Secretary",
    "TRA22": "Shipping/Receiving Clerk",
    "TRA23": "Subway/Light Rail Operator",
    "TRA24": "Ticket Agent",
    "TRA25": "Transportation Specialist",
    "TRA26": "Travel Agent",
    "TRA17": "Other"
}