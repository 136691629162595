import {useCallback, useMemo} from "react";
import {isWithinInterval} from "date-fns";


const useCheckHolidays = (holidays) => {

    holidays = holidays || [];

    const defaults = useMemo(() => {
        return {
            display: false,
            title: "Holiday",
            message: "",
            condensed: []
        };
    }, []);

    const checkStatus = useCallback(() => {

        const localTime = new Date(new Date().toLocaleString(process.env.LOCALE_STRING, {
            timeZone: process.env.DEFAULT_TIMEZONE
        }));

        const isHolidayPeriod = (start, end) => {
            return isWithinInterval(localTime, {
                start: new Date(start),
                end: new Date(end)
            });
        };

        const getHolidayInformation = () => {
            for (let i in holidays) {
                const {from, to} = holidays[i];
                if (isHolidayPeriod(from, to)) {
                    return {
                        display: true,
                        ...holidays[i]
                    };
                }
            }
            return {};
        };

        return {
            ...defaults,
            ...getHolidayInformation()
        };

    }, [defaults, holidays]);

    return [checkStatus()];

};


export default useCheckHolidays;