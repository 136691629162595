export const getFormData = (target) => {

    const data = {};

    if (!target) {
        return data;
    }

    const formData = new FormData(target);
    for (const pair of formData.entries()) {
        data[pair[0]] = pair[1];
    }

    return data;

}