module.exports = {
    "": "Please Select",
    "LAW01": "Airport Security Officer",
    "LAW02": "Animal Control Officer",
    "LAW03": "Attorney",
    "LAW04": "Bailiff",
    "LAW05": "Corrections Officer",
    "LAW06": "Court Clerk/Reporter",
    "LAW07": "Deputy Sheriff",
    "LAW08": "Dispatcher",
    "LAW09": "Examiner",
    "LAW10": "Federal Agent/Marshall",
    "LAW11": "Fire Chief",
    "LAW12": "Fire Fighter/Supervisor",
    "LAW13": "Gaming Officer/Investigator",
    "LAW14": "Highway Patrol Officer",
    "LAW15": "Judge/Hearing Officer",
    "LAW16": "Legal Assistant/Secretary",
    "LAW18": "Paralegal/Law Clerk",
    "LAW19": "Police Chief",
    "LAW20": "Police Detective/Investigator",
    "LAW21": "Police Officer/Supervisor",
    "LAW22": "Private Investigator/Detective",
    "LAW23": "Process Server",
    "LAW24": "Security Guard",
    "LAW25": "Sheriff",
    "LAW17": "Other"
}