module.exports = {

    // ALABAMA
    AL: [
        {
            regex: /^[0-9]{7,8}$/,
            description: `7 or 8 numbers`
        }
    ],

    // ALASKA
    AK: [
        {
            regex: /^[0-9]{7}$/,
            description: `7 numbers`
        }
    ],

    // ARIZONA
    AZ: [
        {
            regex: /^[a-zA-Z][0-9]{8}$/,
            description: `1 letter followed by 8 numbers`
        }
    ],

    // ARKANSAS
    AR: [
        {
            regex: /^[0-9]{8,9}$/,
            description: `8 or 9 numbers`
        }
    ],

    // CALIFORNIA
    CA: [
        {
            regex: /^[a-zA-Z][0-9]{7}$/,
            description: `1 letter followed by 7 numbers`
        }
    ],

    // COLORADO
    CO: [
        {
            regex: /^[0-9]{9}$/,
            description: `9 numbers`
        }
    ],

    // CONNECTICUT
    CT: [
        {
            regex: /^[0-9]{9}$/,
            description: `9 numbers`
        }
    ],

    // DELAWARE
    DE: [
        {
            regex: /^[0-9]{1,7}$/,
            description: `1-7 numbers`
        }
    ],

    // DISTRICT OF COLOMBIA
    DC: [
        {
            regex: /^[0-9]{7}$/,
            description: `7 numbers`
        }
    ],

    // FLORIDA
    FL: [
        {
            regex: /^[a-zA-Z][0-9]{12,13}$/,
            description: `1 letter followed by 12 or 13 numbers`
        }
    ],

    // GEORGIA
    GA: [
        {
            regex: /^[0-9]{9}$/,
            description: `9 numbers`
        }
    ],

    // HAWAII
    HI: [
        {
            regex: /^(h)[0-9]{8}$/i,
            description: `'H' letter followed by 8 numbers`
        }
    ],

    //IDAHO
    ID: [
        {
            regex: /^[a-zA-Z]{2}[0-9]{6}[a-zA-Z]$/,
            description: `2 letters followed by 6 numbers followed by 1 letter`,
        }
    ],

    // ILLINOIS
    IL: [
        {
            regex: /^[a-zA-Z][0-9]{11}$/,
            description: `1 letter followed by 11 numbers`
        }
    ],

    // INDIANA
    IN: [
        {
            regex: /^[0-9]{10}$/,
            description: `10 numbers`
        }
    ],

    // IOWA
    IA: [
        {
            regex: /^[0-9]{3}[a-zA-Z]{2}[0-9]{4}$/,
            description: `3 numbers followed by 2 letters followed by 4 numbers`
        }
    ],

    // KANSAS
    KS: [
        {
            regex: /^(k)[0-9]{8}$/i,
            description: `'K' followed by 8 numbers`
        }
    ],

    // KENTUCKY
    KY: [
        {
            regex: /^[a-zA-Z][0-9]{8}$/,
            description: `1 letter followed by 8 numbers`
        }
    ],

    // LOUISIANA
    LA: [
        {
            regex: /^(0)[0-9]{8}$/,
            description: `9 numbers`
        }
    ],

    // MAINE
    ME: [
        {
            regex: /^[0-9]{7}$/,
            description: `7 numbers`
        },
        {
            regex: /^[0-9]{7}[a-zA-Z]$/,
            description: `7 numbers followed by 1 letter`,
        }
    ],

    // MARYLAND
    MD: [
        {
            regex: /^[a-zA-Z][0-9]{12}$/,
            description: `1 letter followed by 12 numbers`
        },
        {
            regex: /^(md)[0-9]{11}$/i,
            description: `'MD' followed by 11 numbers`
        }
    ],

    // MASSACHUSETTS
    MA: [
        {
            regex: /^(s)[0-9]{8}$/i,
            description: `'S' followed by 8 numbers`
        },
        {
            regex: /^(sa)[0-9]{7}$/i,
            description: `'SA' followed by 7 numbers`
        }
    ],

    // MICHIGAN
    MI: [
        {
            regex: /^[a-zA-Z][0-9]{12}$/,
            description: `1 letter followed by 12 numbers`
        }
    ],

    // MINNESOTA
    MN: [
        {
            regex: /^[a-zA-Z][0-9]{12}$/,
            description: `1 letter followed by 12 numbers`
        }
    ],

    // MISSISSIPPI
    MS: [
        {
            regex: /^[0-9]{9}$/,
            description: `9 numbers`
        }
    ],

    // MISSOURI
    MO: [
        {
            regex: /^[a-zA-Z][0-9]{9}$/,
            description: `1 letter followed by 9 numbers`
        }
    ],

    // MONTANA
    MT: [
        {
            regex: /^[0-9]{13}$/,
            description: `13 numbers`
        }
    ],

    // NEBRASKA
    NE: [
        {
            regex: /^[a-zA-Z][0-9]{8}$/,
            description: `1 letter followed by 8 numbers`
        }
    ],

    // NEVADA
    NV: [
        {
            regex: /^[0-9]{12}$/,
            description: `12 numbers`
        }
    ],

    // NEW HAMPSHIRE
    NH: [
        {
            regex: /^[a-zA-Z]{3}[0-9]{8}$/,
            description: `3 letters followed by 8 numbers`
        },
        {
            regex: /^[0-9]{2}[a-zA-Z]{3}[0-9]{5}$/,
            description: `2 numbers followed by 3 letters followed by 5 numbers`
        }
    ],

    // NEW JERSEY
    NJ: [
        {
            regex: /^[a-zA-Z][0-9]{14}$/,
            description: `1 letter followed by 14 numbers`
        }
    ],

    // NEW MEXICO
    NM: [
        {
            regex: /^[0-9]{9}$/,
            description: `9 numbers`
        }
    ],

    // NEW YORK
    NY: [
        {
            regex: /^[a-zA-Z][0-9]{7}$/,
            description: `1 letter followed by 7 numbers`,
        },
        {
            regex: /^[a-zA-Z][0-9]{18}$/,
            description: `1 letter followed by 18 numbers`,
        },
        {
            regex: /^[0-9]{8,9}$/,
            description: `8-9 numbers`,
        },
        {
            regex: /^[0-9]{16}$/,
            description: `16 numbers`,
        },
        {
            regex: /^[a-zA-Z]{8}$/,
            description: `8 letters`,
        },
    ],

    // NORTH CAROLINA
    NC: [
        {
            regex: /^[0-9]{1,12}$/,
            description: `1-12 numbers`
        }
    ],

    // NORTH DAKOTA
    ND: [
        {
            regex: /^[a-zA-Z]{3}[0-9]{6}$/,
            description: `3 letters followed by 6 numbers`
        }
    ],

    // OHIO
    OH: [
        {
            regex: /^[a-zA-Z]{2}[0-9]{6}$/,
            description: `2 letters followed by 6 numbers`
        }
    ],

    // OKLAHOMA
    OK: [
        {
            regex: /^[a-zA-Z][0-9]{9}$/,
            description: `1 letter followed by 9 numbers`
        }
    ],

    // OREGON
    OR: [
        {
            regex: /^[A-Za-z][0-9]{6,7}$/,
            description: `1 letter followed by 6 or 7 numbers`
        }
    ],

    // PENN
    PA: [
        {
            regex: /^[0-9]{8}$/,
            description: `8 numbers`
        }
    ],

    // RHODE ISLAND
    RI: [
        {
            regex: /^[0-9]{7}$/,
            description: `7 numbers`
        },
        {
            regex: /^(v)[0-9]{6}$/i,
            description: `'V' followed by 6 numbers`
        }
    ],

    // SOUTH CAROLINA
    SC: [
        {
            regex: /^[0-9]{5,11}$/,
            description: `5-11 numbers`
        }
    ],

    // SOUTH DAKOTA
    SD: [
        {
            regex: /^[0-9]{8}$/,
            description: `8 numbers`
        }
    ],

    // TENNESSEE
    TN: [
        {
            regex: /^[0-9]{7,9}$/,
            description: `7-9 numbers`
        }
    ],

    // TEXAS
    TX: [
        {
            regex: /^[0-9]{8}$/,
            description: `8 numbers`
        }
    ],

    // UTAH
    UT: [
        {
            regex: /^[0-9]{4,10}$/,
            description: `4-10 numbers`
        }
    ],

    // VERMONT
    VT: [
        {
            regex: /^[0-9]{8}$/,
            description: `8 numbers`
        },
        {
            regex: /^[0-9]{7}(a)$/i,
            description: `7 numbers followed by 'A'`,
        }
    ],

    // VIRGINIA
    VA: [
        {
            regex: /^[a-zA-Z][0-9]{9,11}$/,
            description: `1 letter followed by 9-11 numbers`
        },
        {
            regex: /^[0-9]{9}$/,
            description: `9 numbers`
        }
    ],

    // WASHINGTON
    WA: [
        {
            regex: /^[a-zA-Z]{7}[0-9]{3}[a-zA-Z0-9]{2}$/,
            description: `7 letters followed by 3 numbers followed by 2 letters or numbers`
        }
    ],

    // WEST VIRGINIA
    WV: [
        {
            regex: /^[0-9]{7}$/,
            description: `7 numbers`
        },
        {
            regex: /^[a-zA-Z][0-9]{6}$/,
            description: `1 letter followed by 6 numbers`
        },
        {
            regex: /^[a-zA-Z]{2}[0-9]{5}$/,
            description: `2 letters followed by 5 numbers`
        }
    ],

    // WISCONSIN
    WI: [
        {
            regex: /^[a-zA-Z][0-9]{13}$/,
            description: `1 letter followed by 13 numbers`
        }
    ],

    //WYOMING
    WY: [
        {
            regex: /^[0-9]{9}$/,
            description: `9 numbers`
        }
    ]

};
