module.exports = {

    display: true,

    modals: [
        {
            "modal": "DriverConfirmationModal",
            "heading": {
                "default": [
                    "We didn't find driver details for ",
                    "driver_name",
                    "Please add your driver details below."
                ],
                "partial": [
                    "We didn't find driver details for ",
                    "driver_name",
                    "Are you one of the drivers listed? ",
                    "If not, please add your driver details below."
                ]
            }
        }
    ]

}