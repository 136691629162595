import states from "@config/states";

const getStateList = () => {

    const KEY = 'enum', LABEL = 'label';

        let stateOptions = [];
        states.forEach(state => {
            let stateOption = {};
            stateOption.value = state[KEY];
            stateOption.label = state[LABEL];
            stateOptions.push(stateOption);
        });
        return stateOptions;

}


export default getStateList;