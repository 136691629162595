module.exports = {
    "": "Please Select",
    "GOV01": "Accountant/Auditor",
    "GOV02": "Administrative Assistant",
    "GOV03": "Analyst",
    "GOV04": "Attorney",
    "GOV05": "Chief Executive",
    "GOV06": "Clerk",
    "GOV07": "Commissioner",
    "GOV08": "Council Member",
    "GOV09": "Director/Administrator",
    "GOV10": "Enlisted Military Personnel (E1-E4)",
    "GOV12": "Legislator",
    "GOV13": "Mayor/City Manager",
    "GOV14": "Meter Reader",
    "GOV15": "NCO (E5-9)",
    "GOV16": "Officer - Commissioned",
    "GOV17": "Officer - Warrant",
    "GOV19": "Park Ranger",
    "GOV20": "Planner",
    "GOV21": "Postmaster",
    "GOV22": "Receptionist/Secretary",
    "GOV23": "Regulator",
    "GOV24": "US Postal Worker",
    "GOV18": "Other"
}