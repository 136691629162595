import YourAddressStep from "./0-your-address"
import YourDetailsStep from "./1-your-details"
import DriverDetailsStep from "./2-driver-details"
import VehicleDetailsStep from "./3-vehicle-details"
import CurrentCarrierStep from "./4-current-carrier"
import ContactDetailsStep from "./5-contact-details"
import QuoteStep from "./6-quotes"
import TermsStep from "./7-terms"
import PaymentStep from "./8-payment"
import SuccessStep from "./9-success"
import EndStep from "./10-end"


module.exports = [
    YourAddressStep,
    YourDetailsStep,
    DriverDetailsStep,
    VehicleDetailsStep,
    CurrentCarrierStep,
    ContactDetailsStep,
    QuoteStep,
    TermsStep,
    PaymentStep,
    SuccessStep,
    EndStep
];