import SVG from "@components/general/svg";
import {HOLIDAYS, PARTNER_IMAGE_PATH, SHARED_ASSETS_PATH} from "@constants";
import useCheckHolidays from "@hooks/useCheckHolidays";


const Navigation = (props) => {

    let [holidayStatus] = useCheckHolidays(HOLIDAYS);

    const {phoneNumber, image, alt, partner} = props.campaignDetails || {};

    let logo = <SVG img={SHARED_ASSETS_PATH + "svg/logo-red-text-navy.svg"} alt="SimplyIOA logo" className={"our-logo"}/>;
    let link = "https://www.simplyioa.com";

    const imagePath = image ? PARTNER_IMAGE_PATH + image + ".svg" : "";


    return (
        <nav id="navigation-container">
            <div className={`simplyioa navigation ${partner ? "partner" : ""}`}>
                    <span className={"logos"}>
                        <a href={link} aria-label="Home" className="no-external no-local-external"
                           target="_self">{logo}</a>
                        {image && (
                            <>
                                <SVG className={"partner-and"} src={'and'} aria-label="In partnership with"/>
                                <SVG className={"partner-logo"} img={imagePath} alt={alt}/>
                            </>
                        )}
                    </span>
                {holidayStatus.display ? (
                    <div className={"announcements"}>
                        <div className={"d-flex align-items-center"}>
                            <img src={SHARED_ASSETS_PATH + "img/agent-smiley-005.png"} alt=""/>
                        </div>
                        <div className={"d-none d-md-block"}>
                            <div className={"semi-bold"}>
                                <span className="primary-text">{holidayStatus.title}</span> <span
                                className="secondary-text">opening hours</span>
                            </div>
                            <div className={"semi-bold small-text"}>
                                <span>{holidayStatus.message}</span>
                            </div>
                        </div>
                        <div className={"d-md-none"}>
                            {holidayStatus.mobile.map((message, index) => {
                                return (
                                    <div key={index} className={"semi-bold small-text"}>
                                        <span>{message}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                ) : phoneNumber ? (
                        <span className={"partner-phone"}>
                            <span className="d-none d-md-inline semi-bold">
                                Call an agent <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                            </span>
                            <span className="d-inline-block d-md-none"><a href={`tel:${phoneNumber}`}>
                                <SVG src={"nav-phone"} alt={"Call an agent"} aria-label={"Call an agent"}/></a>
                            </span>
                        </span>
                    ) : (<span/>)}
            </div>
        </nav>
    );

}

export default Navigation;