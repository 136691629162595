import SVG from "@components/general/svg";
import SpotifyTrackingPixel from "@vendor/spotify-tracking-pixel";
import GoogleTagManager from "@vendor/google-tag-manager";
import {address, phone, footer_primary_links, footer_secondary_links} from "@config/site";
import AgentIcon from "@components/general/agent-icon";
import useCheckOpeningHours from "@hooks/useCheckOpeningHours";
import {HOLIDAYS, BUSINESS_HOURS, SHARED_ASSETS_PATH} from "@constants";
import useCheckHolidays from "@hooks/useCheckHolidays";
import {useEffect, useState} from "react";


const Footer = () => {

    const [holidayStatus] = useCheckHolidays(HOLIDAYS);
    const [status, options] = useCheckOpeningHours(BUSINESS_HOURS, holidayStatus.display);

    const [displayStatus, setDisplayStatus] = useState("");

    useEffect(() => {
        setDisplayStatus(status);
        const noSellButton = document.getElementById("DoNotSellOrShare");
        if (noSellButton) {
            noSellButton.addEventListener("keypress", (e) => {
                if (e.key === "Enter") {
                    noSellButton.click();
                }
            });
        }

        return () => {
            const noSellButton = document.getElementById("DoNotSellOrShare");
            if (noSellButton) {
                noSellButton.removeEventListener("keypress", () => {});
            }
        }

    }, []);

    const year = new Date().getFullYear();


    return (
        <>
            <footer className={"footer"} id="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 col-xl-4 no-padding">
                            <div className="footer-contact">
                                <div className="footer-phone">
                                    <SVG className={'phone-logo'} img={SHARED_ASSETS_PATH + 'svg/phone-outline-red.svg'} alt={'Phone'}/>
                                    <h2 className={'phone-title'}>Speak to one of our friendly agents</h2>
                                    <a className={'phone-number'} href={`tel: ${phone}`}>{phone}</a>
                                    <AgentIcon
                                        status={displayStatus}
                                        options={options}
                                    />
                                </div>
                                <div className="footer-address">
                                    <SVG className="address-logo" img={SHARED_ASSETS_PATH + 'svg/location-outline-red.svg'} alt={'Address'}/>
                                    <address>
                                        {address.map((line, index) => {
                                            return (
                                                <div key={index}>{line}</div>
                                            )
                                        })}
                                    </address>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 d-md-none no-padding">
                            <div className="divider lightNavy"/>
                        </div>

                        <div className="col-12 col-md-6 col-xl-8 no-padding">
                            <div className="footer-links">

                                <div className="footer-logo">
                                    <SVG img={SHARED_ASSETS_PATH + 'svg/logo-red-text-white.svg'} aria-hidden={true}/>
                                </div>

                                <div className="footer-pages">
                                    <ul>
                                        {footer_primary_links.map((link, index) => {
                                            return (
                                                <li key={index}>
                                                    <a className="no-local-external" data-type="env"
                                                       data-cy={link.testHook} href={link.path}>{link.label}</a>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>


                                <div className="footer-navigation d-none d-xl-block">
                                    <ul>
                                        {footer_secondary_links.map((link, index) => {
                                            return (
                                                <li key={index}>
                                                    <a className="no-local-external" data-type="env"
                                                       data-cy={link.testHook} href={link.path}>{link.label}</a>
                                                </li>
                                            )
                                        })}
                                        <li className="footer-copyright"><a
                                            tabIndex="0"
                                            id={"DoNotSellOrShare"}
                                            className="optanon-toggle-display"
                                            data-cy="th_DoNotSellOrShare"
                                        >Do Not Sell or Share My Personal Information</a></li>
                                        <li className="footer-copyright mt-3">&copy; {year} Insurance Office of America.
                                            All Rights Reserved.
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="row d-xl-none">
                        <div className="divider lightNavy"/>
                    </div>

                    <div className="row d-xl-none">
                        <div className="col-12 no-padding">
                            <div className="footer-navigation">
                                <ul>
                                    {footer_secondary_links.map((link, index) => {
                                        return (
                                            <li key={index}>
                                                <a className="no-local-external" data-type="env"
                                                   data-cy={link.testHook} href={link.path}>{link.label}</a>
                                            </li>
                                        )
                                    })}
                                    <li className="split d-lg-none"></li>
                                    <li><a
                                        tabIndex="0"
                                        className="optanon-toggle-display"
                                        data-cy="th_DoNotSellOrShare"
                                    >Do Not Sell or Share My Personal Information</a></li>
                                    <li className="split d-lg-none"></li>
                                    <li className="footer-copyright">&copy; { year } Insurance Office of America. <br className="d-sm-none"/>All Rights Reserved.</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </footer>

            <SpotifyTrackingPixel/>

            <GoogleTagManager/>

        </>
    );



}

export default Footer;
