module.exports = {
    "": "Please Select",
    "FDS01": "Baker",
    "FDS02": "Bartender",
    "FDS03": "Bellhop",
    "FDS04": "Bus Person",
    "FDS05": "Caterer",
    "FDS06": "Chef",
    "FDS07": "Concessionaire",
    "FDS08": "Concierge",
    "FDS09": "Cook - Restaurant/Cafeteria",
    "FDS10": "Cook/Worker - Fast Food",
    "FDS11": "Delivery Person",
    "FDS12": "Desk Clerk",
    "FDS13": "Dishwasher",
    "FDS14": "Food Production/Packing",
    "FDS15": "Host/Maitre d'",
    "FDS16": "Housekeeper/Maid",
    "FDS17": "Manager",
    "FDS19": "Valet",
    "FDS20": "Waiter/Waitress",
    "FDS21": "Wine Steward",
    "FDS18": "Other"
}