module.exports = {
    "": "Please Select",
    "EDU01": "Administrative Assistant",
    "EDU02": "Audio-Visual Tech",
    "EDU03": "Child/Daycare Worker",
    "EDU04": "Clerk",
    "EDU05": "Counselor",
    "EDU06": "Graduate Teaching Assistant",
    "EDU07": "Instructor-Vocation",
    "EDU08": "Librarian/Curator",
    "EDU10": "Principal",
    "EDU11": "Professor, College",
    "EDU12": "Receptionist/Secretary",
    "EDU13": "Superintendent",
    "EDU14": "Teacher, College",
    "EDU15": "Teacher, K-12",
    "EDU16": "Teaching Assistant/Aide",
    "EDU17": "Tutor",
    "EDU09": "Other"
}