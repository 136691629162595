import SVG from "@components/general/svg";
import {SHARED_ASSETS_PATH} from "@constants";


const AgentIcon = ({status, options}) => {

    const {
        OPEN,
        CLOSED
    } = options;


   return (
       <>
           {status === CLOSED ? (
               <p className={'phone-open'}>
                   <SVG img={SHARED_ASSETS_PATH + 'svg/simply-closed.svg'} aria-hidden={true}/>Closed
               </p>
           ) : status === OPEN ? (
               <p className={'phone-open'}>
                   <SVG img={SHARED_ASSETS_PATH + 'svg/simply-open.svg'} aria-hidden={true}/>Open
               </p>
           ) : (
               <p className={'phone-open'}>
                   <SVG img={SHARED_ASSETS_PATH + 'svg/simply-closed.svg'} aria-hidden={true}/>Opens at 8am (ET)
               </p>
           )}
       </>
   );

}


export default AgentIcon;