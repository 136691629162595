import {formatCoverageValue} from "./format-coverage-value";

export const makeDropdown = (options) => {

    const returnData = [];

    options.forEach(option => {
        const newOption = {};
        newOption.value = option.Value;
        newOption.label = formatCoverageValue(option.Description);
        returnData.push(newOption);
    });

    return returnData;

}
