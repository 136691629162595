module.exports = {
    "": "Please Select",
    "CON01": "Appraiser - Real Estate",
    "CON02": "Boiler Operator/Maker",
    "CON03": "Bricklayer/Mason",
    "CON04": "Carpenter",
    "CON05": "Carpet Installer",
    "CON06": "Concrete Worker",
    "CON07": "Construction - Project Manager",
    "CON08": "Construction Worker",
    "CON09": "Contractor",
    "CON10": "Crane Operator",
    "CON11": "Electrician/Linesman",
    "CON12": "Elevator Technician/Installer",
    "CON13": "Equipment Operator",
    "CON14": "Floor Layer/Finisher",
    "CON15": "Foreman/Supervisor",
    "CON16": "Handyman",
    "CON17": "Heat/Air Technician",
    "CON18": "Inspector",
    "CON19": "Laborer/Worker",
    "CON20": "Metalworker",
    "CON21": "Miner",
    "CON22": "Oil/Gas Driller/Rig Operator",
    "CON24": "Painter",
    "CON25": "Plaster/Drywall/Stucco",
    "CON26": "Plumber",
    "CON27": "Roofer",
    "CON28": "Utility Worker",
    "CON29": "Welder",
    "CON23": "Other"
}