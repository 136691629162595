import fetch from "isomorphic-unfetch";
import {getTokenFromCookie} from "./get-token-from-cookie";

/*
 * Get Fetch API.
 *
 * Get request to API endpoint.
 */
export const getFetchAPI = async (endpoint, timer = 0) => {

    const sleep = m => new Promise(r => setTimeout(r, m))
    await sleep(timer);

    const url = process.env.NEXT_STATIC_RTU_API + "/" + endpoint
    const settings = {
        "async": true,
        "method": "get",
        "headers": {
            'Content-Type': 'application/json',
            'X-AUTO-CSRF-TOKEN': getTokenFromCookie('AUTO-CSRF-TOKEN')
        }
    };

    let statusCode;

    return await fetch(url, settings)
        .then((res) => {
            statusCode = res.status;
            return res.json();
        })
        .then((result) => {
            result.IsSuccessful = statusCode === 200;
            return result;
        }, (error) => {
            return [];
        });
}