/**
 *
 * @namespace licenzo
 */
module.exports = function (){
        let self = this;
        function bindAll(obj) {
            Object.keys(obj).forEach(function (meth) {
                if (typeof obj[meth] === 'function') {
                    obj[meth] = obj[meth].bind(obj);
                }
            });
            return obj;
        }

        //Maryland
        let MD = require('./states/MD/md');
        self.md = bindAll(new MD(self));

        //Washington
        let WA = require('./states/WA/wa');
        self.wa = bindAll(new WA(self));

        //Florida
        let FL = require('./states/FL/fl');
        self.fl = bindAll(new FL(self));

        //Wisconsin
        let WI = require('./states/WI/wi');
        self.wi = bindAll(new WI(self));

};
