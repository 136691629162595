module.exports = {
    "": "Please Select",
    "BNK01": "Accountant/Auditor",
    "BNK02": "Administrative Assistant",
    "BNK03": "Analyst",
    "BNK04": "Appraiser - Real Estate",
    "BNK05": "Bookkeeper",
    "BNK06": "Branch Manager",
    "BNK07": "Broker",
    "BNK08": "Clerk",
    "BNK09": "Collections",
    "BNK10": "Consultant",
    "BNK11": "Controller",
    "BNK12": "CSR/Teller",
    "BNK13": "Director/Administrator",
    "BNK14": "Executive",
    "BNK15": "Financial Advisor",
    "BNK16": "Investment Banker",
    "BNK17": "Investor",
    "BNK18": "Loan/Escrow Processor",
    "BNK19": "Manager - Credit/Loan",
    "BNK20": "Manager - Portfolio/Production",
    "BNK21": "Manager - Property",
    "BNK23": "Realtor",
    "BNK24": "Receptionist/Secretary",
    "BNK25": "Sales Agent/Representative",
    "BNK26": "Trader, Financial Instruments",
    "BNK27": "Underwriter",
    "BNK22": "Other"
}