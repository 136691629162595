import Head from "next/head"
import {FAVICON_PATH, FONTS_PATH} from "@constants";


/**
 * @class SiteHeader
 */
function SiteHeader() {

    return (
        <Head>

            <meta charSet="UTF-8" />
            <meta name="viewport" content="width=device-width, user-scalable=yes, initial-scale=1.0, maximum-scale=5.0, minimum-scale=1.0" />
            <meta httpEquiv="X-UA-Compatible" content="ie=edge" />

            <link rel="preload" href={ FONTS_PATH + "gilroy-ultra-light.woff2" } as={"font"} crossOrigin="anonymous" />
            <link rel="preload" href={ FONTS_PATH + "gilroy-light.woff2" } as={"font"} crossOrigin="anonymous" />
            <link rel="preload" href={ FONTS_PATH + "gilroy-regular.woff2" } as={"font"} crossOrigin="anonymous" />
            <link rel="preload" href={ FONTS_PATH + "gilroy-medium.woff2" } as={"font"} crossOrigin="anonymous" />
            <link rel="preload" href={ FONTS_PATH + "gilroy-semi-bold.woff2" } as={"font"} crossOrigin="anonymous" />
            <link rel="preload" href={ FONTS_PATH + "gilroy-bold.woff2" } as={"font"} crossOrigin="anonymous" />

            <title>SimplyIOA</title>

            <meta name="subject" content="SimplyIOA – Auto and Home Insurance Comparison"/>
            <meta name="description" content="Want fast and reliable coverage? Look no further. We compare America's finest insurance providers to find you the right products for your auto and home needs. The best part? We do it all in three minutes and let you pay online."/>
            <meta name="copyright" content="SimplyIOA"/>
            <meta name="url" content="https://simplyioa.com"/>
            <meta name="identifier-URL" content="https://simplyioa.com"/>
            <meta name="language" content="en"/>
            <meta name="Classification" content="Business"/>
            <meta name="reply-to" content="inquiries@simplyioa.com"/>
            <meta name="owner" content="SimplyIOA"/>
            <meta name="rating" content="General"/>
            <meta name="MobileOptimized" content="360"/>
            <meta name="revised" content="Thursday, May 16th, 2024, 09:00am"/>
            <meta name="revisit-after" content="7 days"/>

            <link rel="apple-touch-icon" sizes="180x180" href={ FAVICON_PATH + "apple-touch-icon.png" } />
            <link rel="icon" type="image/png" sizes="32x32" href={ FAVICON_PATH + "favicon-32x32.png" } />
            <link rel="icon" type="image/png" sizes="16x16" href={ FAVICON_PATH + "favicon-16x16.png" } />
            <link rel="mask-icon" href={ FAVICON_PATH + "safari-pinned-tab.svg" } color="#FF2E2F"/>
            <meta name="apple-mobile-web-app-title" content="SimplyIOA"/>
            <meta name="application-name" content="SimplyIOA"/>
            <meta name="msapplication-TileColor" content="#FF2E2F"/>
            <meta name="theme-color" content="#ffffff"/>

        </Head>
    )
}


export default SiteHeader;
