module.exports = {
    "": "Please Select",
    "SPT01": "Activity/Recreational Assistant",
    "SPT02": "Administrative Assistant",
    "SPT03": "Agent",
    "SPT04": "Athlete",
    "SPT05": "Camp Counselor/Lead",
    "SPT06": "Clerk",
    "SPT07": "Coach",
    "SPT08": "Concessionaire",
    "SPT09": "Director, Program",
    "SPT10": "Event Manager/Promoter",
    "SPT11": "Life Guard",
    "SPT12": "Manager - Fitness Club",
    "SPT14": "Park Ranger",
    "SPT15": "Receptionist/Secretary",
    "SPT16": "Sales - Ticket/Membership",
    "SPT17": "Sports Broadcast/Journalist",
    "SPT18": "Trainer/Instructor",
    "SPT19": "Umpire/Referee",
    "SPT13": "Other"
}