module.exports = {
    "": "Please Select",
    "BUS01": "Account Executive",
    "BUS02": "Administrative Assistant",
    "BUS03": "Buyer",
    "BUS04": "Cashier/Checker",
    "BUS05": "Clerk-Office",
    "BUS06": "Consultant",
    "BUS07": "Customer Service Representative",
    "BUS08": "Director/Administrator",
    "BUS09": "Executive",
    "BUS10": "H.R. Representative",
    "BUS11": "Manager - Department/Store",
    "BUS12": "Manager - District",
    "BUS13": "Manager - Finance/Insurance",
    "BUS14": "Manager - General Operations",
    "BUS15": "Manager - H.R./Public Relations",
    "BUS16": "Manager - Marketing/Sales",
    "BUS17": "Manager/Supervisor - Office",
    "BUS18": "Marketing Researcher",
    "BUS19": "Messenger/Courier",
    "BUS21": "Receptionist/Secretary",
    "BUS22": "Sales - Counter/Rental",
    "BUS23": "Sales - Homebased",
    "BUS24": "Sales - Manufacture Rep",
    "BUS25": "Sales - Retail/Wholesale",
    "BUS26": "Sales - Route/Vendor",
    "BUS20": "Other"
}