import stepInformation from "@config/steps";

/*
 * Step Lookup.
 *
 * Search and return Step information.
 */
export const stepLookup = (searchKey, searchValue, returnKey) => {

    if( searchKey === 'enum') {
        searchValue = parseInt(searchValue);
    }

    let returnStep = stepInformation.find(step => step[searchKey] === searchValue);
    return returnStep ? returnKey.length ? returnStep[returnKey] : returnStep : '';

}