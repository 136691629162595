import Cookies from "universal-cookie";


export const checkAndSetCookie = (param, cookie, expirationDate = '') => {

    const getParamByName = (name) => {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        const regex = new RegExp("[\\?&]" + name + "=([^&#]*)"), results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }

    const cookies = new Cookies();
    const paramValue = getParamByName(param);

    if (paramValue) {
        cookies.set(cookie, paramValue, {
            path: '/',
            secure: process.env.NEXT_STATIC_ENV !== "local",
            domain: process.env.NEXT_STATIC_ENV === "local" ? "localhost" : "simplyioa.com",
            expires: expirationDate
        });
        return paramValue;
    }

    return cookies.get(cookie);

}