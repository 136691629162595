module.exports = {
    "address": [
        "3210 Lake Emma Rd. Suite 3090",
        "Lake Mary, FL, 32746"
    ],
    "phone": "833.872.4467",
    "spamMessage": false,
    "providers": {
        "main": [
            "progressive",
            "nationwide",
            "travelers",
            "safeco"
        ],
        "others": [
            "allstate",
            "auto-owners",
            "bristol-west",
            "dairyland",
            "foremost",
            "hartford",
            "infinity",
            "kemper-auto",
            "mercury",
            "national-general",
            "stillwater"
        ]
    },
    "footer_primary_links": [
        {
            "type": "link",
            "path": "https://car-insurance.simplyioa.com/",
            "label": "Auto insurance",
            "testHook": "th_Link_Auto"
        },
        {
          "type": "link",
          "path": "https://homeowners-insurance.simplyioa.com/",
          "label": "Home insurance",
          "testHook": "th_Link_Homeowner"
        },
        {
          "type": "link",
          "path": "https://www.simplyioa.com/about",
          "label": "About",
          "testHook": "th_Link_About"
        },
        {
            "type": "link",
            "path": "https://www.simplyioa.com/faq",
            "label": "FAQs",
            "testHook": "th_Link_FAQ"
        },
        {
            "type": "link",
            "path": "https://stories.simplyioa.com/",
            "label": "Stories",
            "testHook": "th_Link_Stories"
        },
        {
          "type": "link",
          "path": "https://www.simplyioa.com/contact",
          "label": "Contact",
          "testHook": "th_Link_ContactUs"
        }
    ],
    "footer_secondary_links": [
        {
            "type": "link",
            "path": "https://www.simplyioa.com/terms-and-conditions",
            "label": "Terms & Conditions",
            "testHook": "th_Link_TermsAndConditions"
        },
        {
          "type": "link",
          "path": "https://www.simplyioa.com/privacy",
          "label": "Privacy Policy",
          "testHook": "th_Link_Privacy"
        },
        {
          "type": "link",
          "path": "https://www.simplyioa.com/cookies",
          "label": "Cookie Policy",
          "testHook": "th_Link_Cookies"
        }
    ]

}
