import ReactGA from 'react-ga4';

const initGA = (id) => {
    ReactGA.initialize(id);
};

export const logPageView = (stepName) => {
    ReactGA.set({ page: window.location.pathname + stepName });
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + stepName, page_title: `auto-${stepName}` });
};

export const logValidationError = (fieldName, validationError) => {
    testInit();
    ReactGA.event({
        category: 'Auto',
        action: 'Validation',
        label: `[${fieldName}] ${validationError}`,
    });
};

export const logSubmitEvent = (submitLabel) => {
    testInit();
    if( window.GA4_INITIALIZED === true) {
        ReactGA.event({
            category: 'Auto',
            action: 'Button clicked',
            label: submitLabel
        });
    }
};

export const testInit = () => {
    const id = getAnalyticsId(window.location.hostname);
    if (!window.GA4_INITIALIZED && id !== "") {
        initGA(id);
        window.GA4_INITIALIZED = true;
    }
};

const liveCode = process.env.GA4_LIVE_MEASUREMENT_ID;
const localCode = process.env.GA4_SANDBOX_MEASUREMENT_ID;
const liveURL = process.env.AUTO_LIVE_ENV_HOSTNAME;

const getAnalyticsId = (url) => {
    if (url === liveURL) {
        return liveCode;
    } else {
        return localCode;
    }
}