module.exports = {

    display: true,

    modals: [
        {
            "modal": "DriverSelectionModal",
            "heading": {
                "default": [
                    "We found the following drivers using the information you provided. ",
                    "Please select drivers to include in your quote."
                ],
                "partial": [
                    "We found the following drivers using the information you provided. ",
                    "Please select drivers to include in your quote."
                ]
            }
        },
        {
            "modal": "DriverConfirmationModal",
            "heading": {
                "default": [
                    "Are you one of the drivers listed? ",
                    "If not, please add your driver details below."
                ],
                "partial": [
                    "Are you one of the drivers listed? ",
                    "If not, please add your driver details below."
                ]
            }
        }
    ]

}