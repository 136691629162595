import { Component } from "react";


class GoogleTagManager extends Component
{
    getTagManagerId() {
        if (window.location.hostname === process.env.AUTO_LIVE_ENV_HOSTNAME) {
            return "GTM-N9GQX34";
        } else {
            return "GTM-TCNP8VG";
        }
    }


    componentDidMount() {
        const tagManagerId = this.getTagManagerId();
        if (!!tagManagerId) {
            document.head.prepend(this.createGoogleTagManagerHeadScript(tagManagerId));
            document.body.prepend(this.createGoogleTagManagerNoScriptIFrame(tagManagerId));
        }
    }

    createGoogleTagManagerHeadScript(tagManagerId) {
        
        const headScriptElement = document.createElement("script");
        headScriptElement.innerHTML = this.headScript(tagManagerId);
        const id = document.createAttribute("id");
        id.value = "google-tag-manager-head-script";
        headScriptElement.attributes.setNamedItem(id);
        return headScriptElement;
    }

    createGoogleTagManagerNoScriptIFrame(tagManagerId) {

        const noscriptElement = document.createElement("noscript");
        noscriptElement.innerHTML = this.iframeDom(tagManagerId);
        const id = document.createAttribute("id");
        id.value = "google-tag-manager-noscript-iframe";
        noscriptElement.attributes.setNamedItem(id);
        return noscriptElement;
    }

    iframeDom(tagManagerId) {
        return `<iframe src="https://www.googletagmanager.com/ns.html?id=` + tagManagerId +`" height="0" width="0"></iframe>`;
    }

    headScript(tagManagerId) {
        return `
            <!-- Google Tag Manager -->
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','`+tagManagerId+`');
            <!-- End Google Tag Manager -->`
    }

    render() {
        return null;
    }
}

export default GoogleTagManager;