/*
 * Hijack External Links.
 *
 * Force external links to open in new tabs
 */
export const hijackExternalLinks = () => {
    let anchors = document.getElementsByTagName('a');
    for (let i=0; i<anchors.length; i++){
        let apply = !anchors[i].classList.contains("no-external");
        if (window.location.hostname !== process.env.AUTO_LIVE_ENV_HOSTNAME) {
            apply = !anchors[i].classList.contains("no-local-external");
        }
        if (apply) {
            if (anchors[i].hostname !== window.location.hostname) {
                anchors[i].setAttribute('target', '_blank');
                anchors[i].setAttribute('rel', 'noopener noreferrer');
            }
        }
    }
}