import {
    EXACT_MATCH,
    NON_MATCH,
    PARTIAL_MATCH,
    TEMPLATE_DRIVER_NAME
} from "@config/policyholder/constants";


const updateDriverSettings = (name, settings) => {
    const replaceDriverNameWith = <><span className="primary-text">{name}</span>. </>;
    return {
        ...settings,
        ...settings.modals?.forEach(modal => {
            modal.heading.default = modal.heading.default.map((heading) => {
                return heading === TEMPLATE_DRIVER_NAME ? replaceDriverNameWith : heading
            });
            modal.heading.partial = modal.heading.partial.map((heading) => {
                return heading === TEMPLATE_DRIVER_NAME ? replaceDriverNameWith : heading
            })
        })
    }
}


export const determineDriverSelectionModal = (drivers, name, insuredCode, showModal = true) => {
    
    const {
        firstName,
        middleInitial = "",
        lastName
    } = name;

    const enteredFullDriverName = firstName + " " + (middleInitial ? middleInitial + " " : "") + lastName;

    const exactMatchesToEnteredData = drivers
        .filter((driver) => {
            const APIDriverName = driver.FirstName + " "
                + (driver.MiddleName ? driver.MiddleName + " " : "")
                + driver.LastName;
            return (enteredFullDriverName.toLowerCase() === APIDriverName.toLowerCase());
        });


    let exactMatchesWithoutMiddleInitial = [];
    const enteredStandardDriverName = firstName + " " + lastName;

    if (exactMatchesToEnteredData.length > 0) {
        exactMatchesWithoutMiddleInitial = drivers.filter((driver) => {
            const APIStandardDriverName = driver.FirstName + " " + driver.LastName;
            return (enteredStandardDriverName.toLowerCase() === APIStandardDriverName.toLowerCase())
                && !exactMatchesToEnteredData.find(d => d.ReferenceId === driver.ReferenceId);
        });
    }

    const combinedExactMatches = [...exactMatchesToEnteredData, ...exactMatchesWithoutMiddleInitial];


    const matchingSurnames = drivers
        .filter(driver => (
                driver.LastName.toLowerCase() === lastName.toLowerCase())
            && !combinedExactMatches.find(d => d.ReferenceId === driver.ReferenceId
            )).map(result => {
                result.type = PARTIAL_MATCH;
                return result;
            });


    const otherSurnames = drivers
        .filter(
            driver => driver.LastName.toLowerCase() !== lastName.toLowerCase()
        ).map(result => {
            result.type = NON_MATCH;
            return result;
        });


    const matchCount = combinedExactMatches.length > 1 ? 2 : combinedExactMatches.length;
    const otherCount = otherSurnames.length > 0 ? 1 : 0;
    const settingsFile = require("@config/policyholder/matches-" + matchCount + "_others-" + otherCount);
    const settings = updateDriverSettings(enteredFullDriverName, settingsFile);

    combinedExactMatches.map(driver => {
        driver.type = EXACT_MATCH;
        if (settings.showAge) {
            driver.showAge = true;
        }
        return driver;
    });

    return {
        "search": name,
        "drivers": drivers,
        "matches": combinedExactMatches,
        "partials": matchingSurnames,
        "others": otherSurnames,
        "insured": insuredCode,
        ...settings,
        display: showModal ? settings.display : false
    };

}