module.exports = {
    "": "Please Select",
    "PRD02": "Administrative Assistant",
    "PRD03": "Clerk",
    "PRD04": "Factory Worker",
    "PRD01": "Foreman/Supervisor",
    "PRD05": "Furniture Finisher",
    "PRD06": "Inspector",
    "PRD07": "Jeweler",
    "PRD08": "Machine Operator",
    "PRD10": "Packer",
    "PRD11": "Plant Manager",
    "PRD12": "Printer/Bookbinder",
    "PRD13": "Quality Control",
    "PRD14": "Receptionist/Secretary",
    "PRD15": "Refining Operator",
    "PRD16": "Shoemaker",
    "PRD17": "Tailor/Custom Sewer",
    "PRD18": "Textile Worker",
    "PRD19": "Upholsterer",
    "PRD09": "Other"
}