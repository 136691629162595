/*
 * Prop Exists.
 *
 * Check if a key exists inside an object or a nested child
 */
export const propExists = (obj, path) => {

    return !!path.split('.').reduce((obj, prop) => {
        return obj && obj[prop] ? obj[prop] : undefined;
    }, obj)

}