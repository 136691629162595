import {useCallback, useMemo} from "react";
import {isWithinInterval} from "date-fns";


const useCheckOpeningHours = (businessHours, openingStatus) => {

    businessHours = businessHours || {
        "opens": "08:00",
        "closes": "20:30"
    };

    const status = useMemo(() => {
        return {
            OPEN: "open",
            OPEN_SOON: "soon",
            CLOSED: "closed"
        };
    }, []);

    const checkStatus = useCallback(() => {

        const {
            OPEN,
            OPEN_SOON,
            CLOSED
        } = status;

        const {opens, closes} = businessHours;

        const localTime = new Date(new Date().toLocaleString(process.env.LOCALE_STRING, {
            timeZone: process.env.DEFAULT_TIMEZONE
        }));

        const isBusinessHours = () => {
            const [openingHour, openingMinute] = opens.split(":");
            const [closingHour, closingMinute] = closes.split(":");

            return isWithinInterval(localTime, {
                start: new Date().setHours(~~openingHour, ~~openingMinute),
                end: new Date().setHours(~~closingHour, ~~closingMinute)
            });
        };

        const isClosed = () => {
            return openingStatus;
        };

        const isWeekend = () => {
            return [0, 6].includes(localTime.getDay());
        };


        if (isClosed() || isWeekend()) {
            return CLOSED;
        }

        if (isBusinessHours()) {
            return OPEN;
        }

        return OPEN_SOON;

    }, [businessHours, status]);

    return [checkStatus(), status];

};


export default useCheckOpeningHours;