module.exports = {
    "": "Please Select",
    "INF01": "Administrative Assistant",
    "INF02": "Analyst",
    "INF03": "Clerk",
    "INF04": "Director/Administrator",
    "INF05": "Engineer - Hardware",
    "INF06": "Engineer - Software",
    "INF07": "Engineer - Systems",
    "INF08": "Executive",
    "INF09": "Manager - Systems",
    "INF10": "Network Administrator",
    "INF12": "Programmer",
    "INF13": "Project Coordinator",
    "INF14": "Receptionist/Secretary",
    "INF15": "Support Technician",
    "INF16": "Systems Security",
    "INF17": "Technical Writer",
    "INF18": "Web Developer",
    "INF11": "Other"
}