/*
 * Validate VIN
 */
export const validateVin = (vin) => {

    const check_digit_check = (vin) => {
        let cleaned_vin = vin.toUpperCase();

        let letter_map = {
            A: 1, B: 2, C: 3, D: 4, E: 5, F: 6, G: 7,
            H: 8, J: 1, K: 2, L: 3, M: 4, N: 5, P: 7,
            R: 9, S: 2, T: 3, U: 4, V: 5, W: 6, X: 7,
            Y: 8, Z: 9, 1: 1, 2: 2, 3: 3, 4: 4, 5: 5,
            6: 6, 7: 7, 8: 8, 9: 9, 0: 0
        };
        let weights = [8, 7, 6, 5, 4, 3, 2, 10, 0, 9, 8, 7, 6, 5, 4, 3, 2];

        let products = 0;
        for (let i = 0; i < cleaned_vin.length; i++) {
            products += letter_map[cleaned_vin[i]] * weights[i];
        }

        let check_digit_should_be = products % 11;
        if (check_digit_should_be === 10) {
            check_digit_should_be = 'X';
        }

        return check_digit_should_be == cleaned_vin[8];
    }

    let no_ioq = '[a-hj-npr-z0-9]';
    let matcher = new RegExp("^" + no_ioq + "{8}[0-9xX]" + no_ioq + "{8}$", 'i');
    if(vin.match(matcher) == null) {
        return false;
    }

    return check_digit_check(vin);

}

