module.exports = {

    display: true,

    showAge: true,

    modals: [
        {
            "modal": "DriverConfirmationModal",
            "heading": {
                "default": [
                    "We found multiple drivers with the same name as the applicant. ",
                    "Which driver is you?"
                ],
                "partial": [
                    "We found multiple drivers with the same name as the applicant. ",
                    "Which driver is you?"
                ]
            }
        }
    ]

}