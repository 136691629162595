import states from "@config/states";

/*
 * State Lookup.
 *
 * Return dropdown options if no value is specified
 * Return enum as option unless searchKey is specified
 * Return state object if no key is specified
 */
export const stateLookup = (searchKey, searchValue, returnKey) => {

    if (searchKey === 'enum') {
        searchValue = parseInt(searchValue);
    }

    if (searchKey === 'text') {
        searchValue = searchValue.toLowerCase()
    }

    if (searchKey === 'abv') {
        searchValue = searchValue.toUpperCase()
    }

    let returnState = states.find(state => state[searchKey] === searchValue);
    return returnState ? returnKey.length ? returnState[returnKey] : returnState : '';
}