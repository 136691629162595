import { Component } from "react";
import Utils from "@components/general/AVB_utils";

/**
 * More information on this pixel can be found here https://podsights.com/docs/#javascript-integration
 */
class SpotifyTrackingPixel extends Component
{
    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const codeQueryStringParamValue = urlParams.get('code');

        if (!codeQueryStringParamValue || codeQueryStringParamValue !== "spotify") {
            return;
        }

        if (Utils.EnvironmentIsProduction()) {
            document.body.appendChild(this.getTrackingPixel());
        }
    }

    getTrackingPixel() {
        const scriptSourceCode = this.getPixelScriptSourceCode();
        const pixelScriptElement = document.createElement("script");
        pixelScriptElement.innerHTML = scriptSourceCode;

        // Please note this ID is only added to aid with identifying if the pixel has been injected, it serves no other purpose.
        const id = document.createAttribute("id");
        id.value = "spotify-tracking-pixel";
        pixelScriptElement.attributes.setNamedItem(id);

        return pixelScriptElement;
    }

    getPixelScriptSourceCode() {
        return `
        (function (w, d) {
            var id = 'pdst-capture', n = 'script';
            if (!d.getElementById(id)) {
                w.pdst = w.pdst || function () { (w.pdst.q = w.pdst.q || []).push(arguments); };
                var e = d.createElement(n); e.id = id; e.async = 1;
                e.src = 'https://cdn.pdst.fm/ping.min.js';
                var s = d.getElementsByTagName(n)[0];
                s.parentNode.insertBefore(e, s);
            }
            w.pdst('conf', { key: 'c15d8c588d3c4823beb818e33b463ee1' })
            w.pdst('view')
            })(window, document);`;
    }

    render() {
        return null;
    }
}

export default SpotifyTrackingPixel;