module.exports = {
    "": "Please Select",
    "AGR01": "Agriculture Inspector/Grader",
    "AGR02": "Arborist",
    "AGR03": "Clerk",
    "AGR04": "Equipment Operator",
    "AGR05": "Farm/Ranch Owner",
    "AGR06": "Farm/Ranch Worker",
    "AGR07": "Fisherman",
    "AGR08": "Florist",
    "AGR09": "Laborer/Worker",
    "AGR10": "Landscaper",
    "AGR11": "Landscaping/Nursery Worker",
    "AGR12": "Logger",
    "AGR13": "Mill Worker",
    "AGR15": "Ranger",
    "AGR16": "Supervisor",
    "AGR17": "Timber Grader/Scaler",
    "AGR14": "Other"
}