module.exports = {
    "": "Please Select",
    "INS01": "Accountant/Auditor",
    "INS02": "Actuarial Clerk",
    "INS03": "Actuary",
    "INS04": "Administrative Assistant",
    "INS05": "Agent/Broker",
    "INS06": "Analyst",
    "INS07": "Attorney",
    "INS08": "Claims Adjuster",
    "INS09": "Clerk",
    "INS10": "Commissioner",
    "INS11": "Customer Service Representative",
    "INS12": "Director/Administrator",
    "INS13": "Executive",
    "INS14": "Insurance CSR",
    "INS16": "Product Manager",
    "INS17": "Receptionist/Secretary",
    "INS18": "Sales Representative",
    "INS19": "Underwriter",
    "INS15": "Other"
}